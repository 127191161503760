
export default {
  name: 'ActionCompletedMessage',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    icon: {
      type: Array,
      default: () => {
        return ['fas', 'sun'];
      },
    },
  },
};
